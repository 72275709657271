// line 免註冊登入
function lineLoginWithoutReg(groupLinkId, lineUserId) {
  const lineLoginWithoutRegApi = `${process.env.VUE_APP_API}/apps/line/loginWithoutRegistration`
  const data = {
    groupLinkId: groupLinkId,
    appUserId: lineUserId
  }
  const result = new Promise((resolve, reject) => {
    $.ajax({
      type: 'POST',
      async: true,
      url: lineLoginWithoutRegApi,
      data: data,
      contentType: 'application/x-www-form-urlencoded',
      success: resolve,
      error: reject,
    })
  })
  return result
}

export { lineLoginWithoutReg }